import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause } from 'lucide-react';

const videos = [
  { id: 1, title: 'About Oriv', url: '/videos/Oriv-HealthANDFintech-Prez_Final.mp4', description: 'Health and Fintech Presentation' },
];

const VideoPlayer = ({ video }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isControlsVisible, setIsControlsVisible] = useState(true);
  const videoRef = useRef(null);
  const controlsTimeoutRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
    showControls();
  };

  const showControls = () => {
    setIsControlsVisible(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
    }
    if (isPlaying) {
      controlsTimeoutRef.current = setTimeout(() => {
        setIsControlsVisible(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (videoRef.current) {
        const windowWidth = window.innerWidth;
        videoRef.current.style.width = `${windowWidth * 0.8}px`;
        videoRef.current.style.height = `${(windowWidth * 0.8) * (9/16)}px`; // Assuming 16:9 aspect ratio
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div 
      className="relative w-full flex justify-center"
      onMouseEnter={showControls}
      onMouseMove={showControls}
      onMouseLeave={() => isPlaying && setIsControlsVisible(false)}
    >
      <video 
        ref={videoRef}
        src={video.url} 
        className="w-4/5 max-w-full"
        style={{ aspectRatio: '16/9' }}
        onClick={togglePlay}
      />
      <button 
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white p-4 rounded-full transition-opacity duration-300 ${
          isControlsVisible ? 'opacity-50 hover:opacity-100' : 'opacity-0'
        }`}
        onClick={togglePlay}
      >
        {isPlaying ? <Pause size={32} /> : <Play size={32} />}
      </button>
      <p className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white bg-black bg-opacity-50 px-2 py-1 rounded transition-opacity duration-300 ${
        isControlsVisible ? 'opacity-100' : 'opacity-0'
      }`}>
        {isPlaying ? 'Now playing' : 'Click to play'}
      </p>
    </div>
  );
};

const VideoStreamingApp = () => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 text-center">Welcome to the Oriv Presentation</h1>
      <VideoPlayer video={videos[0]} />
      <div className="mt-4 text-center">
        <h2 className="text-xl font-bold">{videos[0].title}</h2>
        <p>{videos[0].description}</p>
      </div>
    </div>
  );
};

export default VideoStreamingApp;