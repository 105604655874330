import React from 'react';
import VideoStreamingApp from './components/VideoStreamingApp';

function App() {
  return (
    <div className="App">
      <VideoStreamingApp />
    </div>
  );
}

export default App;